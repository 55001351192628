/* app/ui/rotator/rotator */

import $ from 'jquery';
import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';

var $rotators;

var selectors = {
	wrapper: '.js-rotator__wrapper',
	rotator: '.js-rotator',
	prev: '.js-rotator__prev',
	next: '.js-rotator__next',
	carouselItem: '.js-carousel-item'
};

var dataSelectors = {
	flickity: 'flickity-instance'
};

var module = {
	init: function ($elms) {
		module._initElements($elms);
		module._initRotators();
		module._initEvents();
	},

	_initElements: function ($elms) {
		$rotators = $elms;
	},

	_initRotators: function () {
		var i;
		var $rotator;

		for (i = 0; i < $rotators.length; i++) {
			$rotator = $rotators.eq(i);
			module._initRotator($rotator);
		}
	},

	_initRotator: function ($rotator) {
		var rotatorType = $rotator.attr('data-rotator-type') || 'content';
		var rotatorNav = $rotator.attr('data-rotator-nav') || '';
		var settings = module._getRotatorSettings($rotator, rotatorType, rotatorNav);
		var thisFlick = new Flickity($rotator[0], settings);

		$rotator.data(dataSelectors.flickity, thisFlick);

		if (!settings.wrapAround) {
			thisFlick.on('change', module._setArrowStates($rotator));
			module._setArrowStates($rotator).call(thisFlick, 0);
		}
	},

	_initEvents: function () {
		$(selectors.prev).on('click', module._prev);
		$(selectors.next).on('click', module._next);
	},

	// Return the settings for the type of rotator that has been initialised
	// These can be edited, and new types can be added
	// Documentation for the options can be found here: https://flickity.metafizzy.co/
	_getRotatorSettings: function ($rotator, rotatorType, rotatorNav) {
		var settings;
		var arrowShape = {
			x0: 10,
			x1: 60, y1: 50,
			x2: 65, y2: 45,
			x3: 20
		};

		switch (rotatorType) {

			case 'image':
				settings = {
					prevNextButtons: false,
					autoPlay: false,
					wrapAround: false,
					lazyLoad: true
				};
				break;

			case 'gallery':
				settings = {
					arrowShape: arrowShape,
					wrapAround: true,
					lazyLoad: 1,
					pageDots: false
				};
				break;

			case 'carousel':
				settings = {
					arrowShape: arrowShape,
					wrapAround: true,
					lazyLoad: 6,
					cellAlign: 'left'
				};
				break;

			case 'experience':
				settings = {
					prevNextButtons: false,
					wrapAround: true,
					lazyLoad: 3,
					pageDots: false,
					cellAlign: 'left'
				};
				break;

			case 'accommodation':
				settings = {
					prevNextButtons: false,
					wrapAround: true,
					lazyLoad: 3,
					pageDots: false,
					cellAlign: 'left'
				};
				break;

			case 'gallery-nav':

				if (rotatorNav !== "") {

					settings = {
						asNavFor: rotatorNav,
						pageDots: false,
						contain: true
					};
				}

				break;

			case 'testimonials':
				settings = {
					arrowShape: arrowShape,
					wrapAround: true,
					lazyLoad: true,
					pageDots: false
				};
				break;

			case 'benefits-carousel':
				settings = {
					prevNextButtons: false,
					autoPlay: false,
					wrapAround: false,
					pageDots: false,
					cellAlign: 'left'
				};
				break;

			case 'package-rotator':
				settings = {
					prevNextButtons: true,
					autoPlay: false,
					wrapAround: true,
					lazyLoad: true,
					cellAlign: 'left',
					fade: true
				};
				break;

			case 'pathway':
				settings = {
					prevNextButtons: true,
					autoPlay: false,
					wrapAround: true,
					lazyLoad: 10,
					lazyLoad: true,
					cellAlign: 'left',
					pageDots: false,
					draggable: false
				};

				function CarouselArrows() {
					var windowWidth = $(window).width();
					var item = $rotator
					var items = item.find(selectors.carouselItem).length;

					if (items <= 4) {
						$(item).removeClass("flickity-enabled");
					}

					if (windowWidth <= 1280) {
						if (items <= 4) {
							$(item).addClass("flickity-enabled");
						}

						if (items == 2) {
							$(item).removeClass("flickity-enabled");
						}
					}

					if (windowWidth <= 480) {
						if (items <= 4) {
							$(item).addClass("flickity-enabled");
						}
					}
				};

				$(document).ready(function () {
					CarouselArrows();
				});

				$(window).resize(function () {
					CarouselArrows();
				});

				break;

			default:
				settings = {
					prevNextButtons: false,
					autoPlay: false,
					wrapAround: false,
					lazyLoad: true,
				};
		}
		return settings;
	},

	_prev: function (e) {
		var $wrapper = $(e.target).closest(selectors.wrapper);
		var $rotator = $wrapper.find(selectors.rotator);
		var flick = $rotator.data(dataSelectors.flickity);

		flick.previous();
	},

	_next: function (e) {
		var $wrapper = $(e.target).closest(selectors.wrapper);
		var $rotator = $wrapper.find(selectors.rotator);
		var flick = $rotator.data(dataSelectors.flickity);

		flick.next();
	},

	_setArrowStates: function ($rotator) {
		var $wrapper = $rotator.closest(selectors.wrapper);
		var $prev = $wrapper.find(selectors.prev);
		var $next = $wrapper.find(selectors.next);

		return function (index) {
			var flick = this;
			var length = flick.slides.length;

			var isFirst = index === 0;
			var isLast = index >= length-1;

			$prev.prop('disabled', isFirst);
			$next.prop('disabled', isLast);
		}
	}
};

var Rotator = {
	init: module.init
};

export { Rotator };

/* app/ui/nav/small */

import $ from 'jquery';
import { publish, subscribe, unsubscribe } from 'Util/pubsub';

var NavSmall;
var $header;
var $nav;
var classes;
var selectors;

var $navItems;
var $navLinks;
var $navToggle;
var elementsInit = false;

var NavSmall = {
	init: function($elm, selectorList, classList) {
		NavSmall = this;
		$nav = $elm;
		selectors = selectorList;
		classes = classList;

		NavSmall._initElements();
		NavSmall._initSubscriptions();
		NavSmall._initEvents();
	},

	_initElements: function () {
		if (!elementsInit) {
			$header = $(selectors.header);
			$navItems = $nav.find(selectors.navItem);
			$navLinks = $nav.find('.js-megamenu__heading');
			$navToggle = $('.js-header__nav-toggle');
			elementsInit = true;
		}
	},

	_initSubscriptions: function() {
		subscribe('/nav/searchToggle', NavSmall._closeNav);
		subscribe('/nav/langToggle', NavSmall._closeNav);
	},

	_initEvents: function() {
		$navToggle.on('click', NavSmall._toggleSmallMenu);
		$navLinks.on('click', NavSmall._openSubMenu);
	},

	unbind: function () {
		unsubscribe('/nav/searchToggle', NavSmall._closeNav);
		unsubscribe('/nav/langToggle', NavSmall._closeNav);
		$navToggle.off('click', NavSmall._toggleSmallMenu);
		$navLinks.off('click', NavSmall._openSubMenu);
		NavSmall._resetMenu();
	},

	// close primary nav menu if open
	_closeNav: function () {
		if ($navToggle.attr('aria-expanded') === 'true') {
			$navToggle.trigger('click');
		}
	},

	// publish nav toggle event
	// show/hide primary nav menu dependant on current state
	// toggle class on menu button
	_toggleSmallMenu: function (event) {
		event.preventDefault();
		publish('/nav/navToggle');
		if ($nav.attr('aria-expanded') === 'true') {
			$nav.attr('aria-expanded', false);
			$header.removeClass(classes.headerNavOpen);
		} else {
			$nav.attr('aria-expanded', true);
			$header.addClass(classes.headerNavOpen);
		}
		if ($navToggle.attr('aria-expanded') === 'true') {
			$navToggle.attr('aria-expanded', false);
		} else {
			$navToggle.attr('aria-expanded', true);
		}

		var title = $navToggle.attr('title');
		var altTitle = $navToggle.attr('data-alt-title');
		$navToggle.attr('title', altTitle);
		$navToggle.attr('data-alt-title', title);

		if ($nav.attr('aria-expanded') === 'false') {
			$nav.find('[aria-expanded="true"]').attr('aria-expanded', false);
		}
	},

	_openSubMenu: function (event) {
		event.preventDefault();
		var $link = $(this);
		var $item = $link.closest(selectors.navItem);

		if ($item.attr('aria-expanded') !== 'true') {
			$nav.find('[aria-expanded="true"]').attr('aria-expanded', false);
		}
		if ($item.attr('aria-expanded') === 'true') {
			$item.attr('aria-expanded', false);
		} else {
			$item.attr('aria-expanded', true);
		}
	},

	// collapse nav and any expanded children
	_resetMenu: function() {
		$nav.attr('aria-expanded', false);
		$header.removeClass(classes.headerNavOpen);
		$navItems.attr('aria-expanded', false);
		$navToggle.attr('aria-expanded', false);
	}
};

export { NavSmall };
